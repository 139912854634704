import React, { useState } from 'react'
import * as DialogPrimitive from '@radix-ui/react-dialog'
import { CgClose } from 'react-icons/cg'

import { useDialog } from 'common/components/Dialog/hooks/useDialog'
import { DialogContext } from 'common/components/Dialog/context'

export function Dialog({ children, open: controlledOpen, setOpen: controlledSetOpen }) {
  const [dialogOpen, setDialogOpen] = useState(false)

  const contextValue = controlledOpen !== undefined ? [controlledOpen, controlledSetOpen] : [dialogOpen, setDialogOpen]
  return (
    <DialogContext.Provider value={contextValue}>
      <DialogPrimitive.Root open={contextValue[0]}>{children}</DialogPrimitive.Root>
    </DialogContext.Provider>
  )
}

export function DialogContent({
  children,
  header = null,
  overlayProps,
  dialogCloseCb,
  closeDisabled,
  overlayClassNames,
  contentClassNames,
  disableDefaultContentSizeClasses = false,
  closeClassNames,
  titleClassNames,
  contentProps,
}) {
  const [, setDialogOpen] = useDialog()

  return (
    <DialogPrimitive.Portal>
      <DialogPrimitive.Overlay
        className={`
          flex items-center justify-center bg-black bg-opacity-70 fixed inset-0 z-[100]
          ${overlayClassNames ? overlayClassNames : ''}
        `}
        {...overlayProps}
      >
        <DialogPrimitive.Content
          onEscapeKeyDown={() => {
            setDialogOpen(false)
          }}
          className={`
            relative flex flex-col bg-white rounded-xl overflow-hidden 
            ${
              disableDefaultContentSizeClasses
                ? ''
                : 'max-h-[calc(100vh-160px)] max-w-[calc(100vw-16px)] md:max-w-[calc(100vw-48px)] w-[512px]'
            } 
            ${contentClassNames ? contentClassNames : ''}
          `}
          {...contentProps}
        >
          <div className='sticky top-0 left-0 z-10'>
            <DialogPrimitive.Close
              onClick={() => {
                if (dialogCloseCb) {
                  dialogCloseCb()
                }
                setDialogOpen(false)
              }}
              disabled={closeDisabled}
              className={`
                absolute top-4 left-4 p-1 outline-none rounded-full text-tBlack hover:bg-tBlack hover:bg-opacity-10 transition-colors
                ${closeDisabled ? 'cursor-not-allowed' : ''}
                ${closeClassNames ? closeClassNames : ''}
              `}
              aria-label='Close'
            >
              <CgClose className='w-5 h-5' />
            </DialogPrimitive.Close>
            {header && (
              <DialogPrimitive.Title
                className={`font-bold text-2xl text-tBlack text-center p-8 shadow ${
                  titleClassNames ? titleClassNames : ''
                }`}
              >
                {header}
              </DialogPrimitive.Title>
            )}
          </div>
          {children}
        </DialogPrimitive.Content>
      </DialogPrimitive.Overlay>
    </DialogPrimitive.Portal>
  )
}

export function DialogTrigger({ children, className, disabled, onOpenCb, ...otherProps }) {
  const [, setDialogOpen] = useDialog()

  return (
    <DialogPrimitive.Trigger
      {...otherProps}
      disabled={disabled}
      onClick={(e) => {
        if (onOpenCb) {
          onOpenCb(e)
        }
        setDialogOpen(true)
      }}
      className={className}
    >
      {children}
    </DialogPrimitive.Trigger>
  )
}

export function DialogClose({ children, className, dialogCloseCb, ...otherProps }) {
  const [, setDialogOpen] = useDialog()

  return (
    <DialogPrimitive.Close
      {...otherProps}
      onClick={() => {
        if (dialogCloseCb) {
          dialogCloseCb()
        }
        setDialogOpen(false)
      }}
      className={className}
    >
      {children}
    </DialogPrimitive.Close>
  )
}
