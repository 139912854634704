export function getVideoSize({ windowWidth, windowHeight, maxVidWidth, whRatio }) {
  const expectedWidth = windowWidth < maxVidWidth ? windowWidth : maxVidWidth
  const expectedHeight = expectedWidth / whRatio

  if (expectedHeight > windowHeight) {
    // video height becomes higher than window height,
    // start calculating video width based on screen height
    const adjustedHeight = windowHeight
    const adjustedWidth = adjustedHeight * whRatio
    return { videoWidth: adjustedWidth, videoHeight: adjustedHeight }
  } else {
    return { videoWidth: expectedWidth, videoHeight: expectedHeight }
  }
}
