import { useState } from 'react'
import { FaPlay } from 'react-icons/fa'

import * as DialogPrimitive from '@radix-ui/react-dialog'
import { EmptyStateContainer } from 'common/components/EmptyStateContainer/EmptyStateContainer'
import { Dialog, DialogContent, DialogTrigger } from 'common/components/Dialog/Dialog'
import { useWindowSize } from 'common/hooks/useWindowSize'
import { getVideoSize } from 'common/utils/videoUtils'

export function VideoModal({ videoName, videoUrl, previewImg, exVidOrientation = 'landscape' }) {
  const [videoDialogOpen, setVideoDialogOpen] = useState(false)

  return (
    <Dialog open={videoDialogOpen} setOpen={setVideoDialogOpen}>
      <DialogTrigger className='absolute inset-0 hidden items-center justify-center bg-tBlack bg-opacity-30 group-hover:flex'>
        <FaPlay className='w-5 h-5 text-white' />
      </DialogTrigger>
      <DialogContent
        contentClassNames='!bg-transparent'
        disableDefaultContentSizeClasses={true}
        closeClassNames='!fixed bg-gray-200 hover:!bg-gray-200 hover:!bg-opacity-100 hover:text-black'
        overlayClassNames='!bg-opacity-80 !bg-black'
        contentProps={{
          onPointerDownOutside: () => {
            setVideoDialogOpen(false)
          },
        }}
      >
        <VideoViewer
          videoName={videoName}
          videoUrl={videoUrl}
          previewImg={previewImg}
          exVidOrientation={exVidOrientation}
        />
      </DialogContent>
    </Dialog>
  )
}

function VideoViewer({ videoName, videoUrl, previewImg, exVidOrientation }) {
  const [windowWidth, windowHeight] = useWindowSize()
  const [error, setError] = useState(null)

  const maxVidTitleHeight = 160
  const { videoWidth, videoHeight } = getVideoSize({
    windowWidth,
    windowHeight: windowHeight - maxVidTitleHeight,
    maxVidWidth: 1280,
    whRatio: exVidOrientation === 'landscape' ? 16 / 9 : 9 / 16,
  })

  if (error) {
    return <EmptyStateContainer text={error} containerStyles={{ width: videoWidth, height: videoHeight }} />
  }

  return (
    <div className='mx-auto' style={{ width: videoWidth }}>
      <DialogPrimitive.Title
        className={`text-3xl md:text-5xl font-bold text-white mb-4 capitalize w-full line-clamp-2 ${
          exVidOrientation === 'portrait' ? 'mx-auto text-center' : ''
        }`}
      >
        {videoName}
      </DialogPrimitive.Title>
      <div
        className='rounded-xl overflow-hidden'
        style={{
          width: videoWidth,
          height: videoHeight,
        }}
      >
        <video poster={previewImg} autoPlay controls className='object-cover w-full h-full'>
          <source onError={() => setError('No video found')} src={videoUrl} />
        </video>
      </div>
    </div>
  )
}
